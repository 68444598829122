import { Actions } from "types/global-state.types";
import { GlobalState } from "types/categories-clock-context.types";

export const globalStateReducer = (
    state: GlobalState,
    { type, payload }: Actions
) => {
    switch (type) {
        case "SELECT_FILTERS":
            return {
                ...state,
                [payload.name]: payload.value
            };
        case "SELECT_FILTERS_MODAL":
            return {
                ...state,
                [payload.name]: {
                    ...state.mobileModalDropdown,
                    isMobileModalDropdownOpen:
                        payload.section === state.mobileModalDropdown.section
                            ? payload.value
                            : true,
                    section: payload.section,
                    data: payload?.data
                }
            };
        case "SELECT_COUNTRY_FILTERS":
            return {
                ...state,
                countries: {
                    ...state.countries,
                    [payload.name]: payload.value
                }
            };
        default:
            throw new Error();
    }
};
