import { useCategoriesClock } from "context/CategoriesClock";
import { useMemo } from "react";
import { SelectType } from "types/select.types";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { categoriesList, measuresList } from "utils/list";
import useWindowResize from "hooks/useWindowResize";
import { t } from "i18next";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const InputSelect = ({ value, name, isFromMap }: SelectType) => {
  const { setFilters } = useCategoriesClock();
  const { isDesktop } = useWindowResize();

  const activeOptions = useMemo(
    () => (name === "category" ? categoriesList : measuresList),
    [name]
  );

  return useMemo(
    () => (
      <Select
        sx={{
          margin: isFromMap ? "0.5rem 0 1rem" : 0,
          background: "#ffffff",
          borderRadius: "3.125rem",
          minWidth: "0",
          maxWidth: isFromMap ? "100%" : "max-content",
          width: "100%",
          height: isFromMap ? "2.5rem" : "auto",
          fontFamily: "Epilogue, sans-serif",
          fontSize: isDesktop && !isFromMap ? "1.56rem" : "1rem",
          fontWeight: "medium",

          "&:hover": {
            background: "#F0FFB6",
          },

          "& > div": {
            minHeight: "auto !important",
            padding: "0.5rem 1rem",
          },
        }}
        value={value}
        onChange={(event: SelectChangeEvent) => {
          setFilters({
            name,
            value: event.target.value,
          });
        }}
        displayEmpty
        MenuProps={{
          PaperProps: {
            className: "scrollable",
            style: {
              maxHeight: name === "category" ? "12rem" : "auto",
              overflowY: "scroll",
            },
          },
        }}>
        {activeOptions.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {t(name)}
          </MenuItem>
        ))}
      </Select>
    ),
    [name, value, setFilters, isDesktop, isFromMap, activeOptions]
  );
};

export default InputSelect;
