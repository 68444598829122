import { t } from "i18next";
import { socialMediaLinks } from "utils/list";
import DynamicSvg from "components/DynamicSvg/DynamicSvg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const ContactUs = () => (
  <div tw="mt-[0.8rem] sm:m-0 md:mt-2">
    <h3 tw="tracking-[0.041rem] uppercase pb-[1.5rem] font-semiBold text-sm">
      {t("footer_contact_us")}
    </h3>
    <a href="mailto:contact@worlddata.io" tw="text-[1.063rem]">
      contact@worlddata.io
    </a>
    <ul tw="flex justify-between gap-[1rem] pt-[1.5rem] max-w-[20rem] md:m-auto">
      {socialMediaLinks.map(({ name, id }) => (
        <li
          key={name}
          tw="w-[3rem] h-[3rem] rounded-[50%] bg-green-400 flex justify-center items-center">
          <a href={id}>
            <DynamicSvg iconName={name} primaryColor="#000114" />
          </a>
        </li>
      ))}
    </ul>
  </div>
);

export default ContactUs;
