import searchIcon from "assets/images/searchIcon.svg";
import arrow from "assets/images/arrowDropdown.svg";
/** @jsxImportSource @emotion/react */
import { theme } from "twin.macro";

export const autoCompleteStyles = {
    "& .MuiAutocomplete-clearIndicator": {
        background: "white",
        visibility: "visible !important"
    },
    "& .MuiAutocomplete-clearIndicator:hover": {
        backgroundColor: "white"
    },
    "& .MuiAutocomplete-endAdornment": {
        top: "unset !important"
    },
    "& .MuiAutocomplete-input": {
        fontWeight: 600,
        width: "100% !important",
        fontSize: "0.9rem"
    },
    "& .MuiAutocomplete-option": {
        fontWeight: 400
    },
    "& .MuiAutocomplete-input::placeholder": {
        opacity: 1,
        fontSize: "0.9rem",
        fontWeight: 600,
        color: theme`colors.blue.800`,
        fontFamily: "Epilogue, sans-serif"
    },
    "& .MuiAutocomplete-popupIndicator svg": {
        display: "none"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: 0,
        paddingTop: "0.1rem",
        paddingBottom: "0.1rem",
        fontSize: "1.063rem",
        lineHeight: "20px",
        paddingRight: "0 !important"
    },
    "& .MuiTextField-root": {
        background: `url(${searchIcon}) no-repeat 100%`,
        backgroundPosition: "right 1rem center",
        backgroundColor: "white",
        paddingRight: 0
    },
    "& .MuiTextField-root:hover": {
        backgroundColor: theme`colors.green.50`
    }
};

export const HeroAutoCompleteStyles = {
    "& .MuiAutocomplete-input": {
        color: theme`colors.blue.800`,
        fontWeight: 500,
        width: "100% !important",
        fontSize: "1.6rem"
    },
    "& .MuiTextField-root": {
        background: `url(${arrow}) no-repeat 100%`,
        backgroundPosition: "right 1rem center",
        border: "2px solid #13173D",
        borderRadius: "3rem",
        backgroundColor: "white"
    },
    "& .MuiOutlinedInput-root": {
        paddingRight: "39px !important"
    }
};
