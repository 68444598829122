import { t } from "i18next";
import { getCountryInfo } from "utils/countries";
import { handleOrdinal } from "utils/utilities";
/** @jsxImportSource @emotion/react */
import "twin.macro";
import {useEffect, useRef} from "react";
import {useCategoriesClock} from "context/CategoriesClock";

const Continents = (data: any) =>{
    const { countries } =
        useCategoriesClock();
    const refs = useRef(null);

    useEffect(() => {
        if (refs.current) {
            // @ts-ignore
            refs.current.scrollIntoView()
        }
    }, [data]);

  return(data.data.map(
    (
      { id, name, continent }: { id: string; name: string; continent: string },
      i: number
    ) => (
      <li
        key={id}
        tw="flex justify-start items-center gap-[1.125rem] border-b border-b-grey-100 py-[1.5rem] font-semiBold text-lg md:text-sm"
        ref={getCountryInfo(id, false)?.name === countries.mapCountry ? refs : null}>
        <span>
          {i + 1}
          <sup>{handleOrdinal(i + 1)}</sup>
        </span>
        {name && (
          <img
            width={38}
            src={getCountryInfo(id, false)?.file_url}
            alt={name}
          />
        )}
        {t(name ? name : continent)}
      </li>
    )
  ));
};

export default Continents;
