import { categoriesList, categoriesTableHeads } from "utils/list";

export const currentYear = new Date().getFullYear().toString();

export const getCategoryCode = (categoryCode: string) =>
  categoriesList.find(({ id }) => id === categoryCode)?.code;

export const handleOrdinal = (number: number) => {
  let ord = "th";

  if (number % 10 === 1 && number % 100 !== 11) {
    ord = "st";
  } else if (number % 10 === 2 && number % 100 !== 12) {
    ord = "nd";
  } else if (number % 10 === 3 && number % 100 !== 13) {
    ord = "rd";
  }

  return ord;
};

export const secondsPerYear = 60 * 60 * 24 * 365;

export const beginningCurrentYear =
  new Date(Number(currentYear), 0, 0).getTime() / 1000;

export const handleCurrentYear = (year: string) => {
  const comparedYear = Number(year) === Number(currentYear);

  return comparedYear;
};

export const handleCategoriesRank = (countryDetails: any[]) =>
  categoriesTableHeads.map(({ code }) =>
    countryDetails.find((category) => category.code === code)
  );

export const roundToMillions = (number: number) => {
  if (!number) {
    return 0;
  }
  return new Intl.NumberFormat("en-US", { maximumSignificantDigits: 8 }).format(
    number / 1000
  );
};

export const roundToThousands = (number: number) => {
  if (!number) {
    return 0;
  }
  return new Intl.NumberFormat("en-US", {
    maximumSignificantDigits: 11,
  }).format(number);
};

const bannerRadius = "0.5rem 0 0 0.5rem";
const bannerTop = "7.5rem";
const bannerRight = 0;
const bannerBackground =
  "radial-gradient(178.54% 179.13% at -5.71% 0%, #141BFF 0%, #6372FA 36.1%, #CCD1FF 62.01%)";
const bannerZ = 10000;

export const bannerStyle = {
  background: bannerBackground,
  width: "14.688rem",
  borderRadius: bannerRadius,
  top: bannerTop,
  right: bannerRight,
  zIndex: bannerZ,
};

export const bannerStyle2 = {
  background: bannerBackground,
  borderRadius: bannerRadius,
  top: bannerTop,
  right: bannerRight,
  zIndex: bannerZ,
};
