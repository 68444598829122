import { useState, useRef, useEffect, useCallback } from "react";
import Tooltip from "components/Main/Map/GeoJson/Tooltip";
import { GeoJSON, MapContainer, ZoomControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import useLeaflet from "hooks/useLeaflet";
import {
  centerMap,
  defaultMapZoom,
  countryStyle,
  getColor,
} from "utils/leaflet";
import { useCategoriesClock } from "context/CategoriesClock";
/** @jsxImportSource @emotion/react */
import { theme } from "twin.macro";

const GeoJson = ({ mapData }: { mapData: any }) => {
  const { countries, category, hoveredLegend, setCountryFilters, setFilters } =
    useCategoriesClock();
  const [mapRef, setMapRef] = useState<any>(null);
  const geoJsonLayer = useRef<any>(null);
  const { handleMapResize } = useLeaflet(mapRef, countries.mapCountry);
  const [tooltipInfo, setTooltipInfo] = useState<any | null>(null);
  const [hoveredCountry, setHoveredCountry] = useState<string | null>(null);

  useEffect(() => {
    window.addEventListener("resize", handleMapResize);

    return () => {
      window.removeEventListener("resize", handleMapResize);
    };
  }, [handleMapResize]);

  const getStyle = useCallback(() => {
    if (mapRef) {
      if (hoveredLegend === null) {
        geoJsonLayer.current.setStyle((feature: any) => ({
          ...countryStyle(
            feature,
            feature.properties.name === countries.mapCountry
          ),
          weight: feature.properties.name === hoveredCountry ? 2 : 0.4,
          color:
            feature.properties.name === hoveredCountry
              ? theme`colors.blue.900`
              : theme`colors.blue.800`,
        }));
      } else {
        const selectedCountries = Object.values(mapRef.target._targets).filter(
          ({ feature }: any) => feature?.spendingLevel === hoveredLegend
        );
        geoJsonLayer.current.setStyle({
          fillOpacity: 0.1,
        });
        selectedCountries.forEach((target: any) =>
          target.setStyle({
            fillColor: getColor(target.feature.spendingLevel)?.color,
            fillOpacity: 1,
            weight: target.feature.properties.name === hoveredCountry ? 2 : 0.4,
            color:
              target.feature.properties.name === hoveredCountry
                ? theme`colors.blue.900`
                : theme`colors.grey.600`,
          })
        );
      }
    }
  }, [hoveredLegend, mapRef, hoveredCountry, countries.mapCountry]);

  useEffect(() => {
    getStyle();
  }, [hoveredLegend, getStyle, hoveredCountry]);

  const zoomToCountry = ({ target }: any) => {
    if (target.feature.spendingLevel) {
      setFilters({
        name: "countryLevel",
        value: target.feature.spendingLevel,
      });
      setCountryFilters({
        name: "mapCountry",
        value: target.feature.properties.name,
      });
      mapRef.target.flyToBounds(target.getBounds());
      target.setStyle({
        fillColor: getColor(target.feature.spendingLevel)?.color,
        fillOpacity: 1,
        weight: 10,
        color: theme`colors.blue.800`,
      });
    }
  };

  const handleResetMap = () => mapRef?.target.flyTo(centerMap, defaultMapZoom);

  useEffect(() => {
    mapRef?.target.invalidateSize();
    if (countries.mapCountry) {
      if (geoJsonLayer?.current) {
        const target: any = Object.values(geoJsonLayer?.current?._layers).find(
          ({ feature }: any) => feature.properties.name === countries.mapCountry
        );
        zoomToCountry({ target });
      }
    } else handleResetMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries.mapCountry, category, mapRef?.target]);

  const resetHighlight = () => {
    setHoveredCountry(null);
    setTooltipInfo(null);
  };

  const highlightFeature = (e: any) => {
    setHoveredCountry(e.target.feature.properties.name);
    setTooltipInfo(e);
  };

  const mapInteractions = (_: any, layer: any) => {
    layer.on({
      mouseout: resetHighlight,
      mouseover: highlightFeature,
      click: zoomToCountry,
    });
  };

  return (
    <MapContainer
      whenReady={setMapRef as any}
      tw="bg-white h-[calc(100% - 14rem)] lg:h-full w-full relative"
      center={centerMap}
      zoom={defaultMapZoom}
      zoomSnap={1.3}
      minZoom={defaultMapZoom}
      zoomControl={false}
      doubleClickZoom={false}
      trackResize={false}
      touchZoom={false}
      scrollWheelZoom={false}>
      <ZoomControl />
      <GeoJSON
        ref={geoJsonLayer}
        data={mapData as any}
        style={(country: any) =>
          countryStyle(country, country!.name === countries.mapCountry)
        }
        onEachFeature={mapInteractions}
      />
      {tooltipInfo?.target.feature.spendingLevel && (
        <Tooltip tooltipInfo={tooltipInfo as any} />
      )}
    </MapContainer>
  );
};

export default GeoJson;
