import { LatLngTuple } from "leaflet";
import { theme } from "twin.macro";
import { legendList } from "utils/list";

export const centerMap: LatLngTuple = [40, 20];
export const defaultMapZoom: number = 1.4;

export const getColor = (spendingLevel: string) =>
  legendList.find(({ id }) => id === spendingLevel);

export const countryStyle = (country: any, isSelected: boolean) => ({
  fillColor: getColor(country.spendingLevel)?.color,
  fillOpacity: 1,
  weight: isSelected ? 4 : 0.4,
  color: theme`colors.blue.800`,
});
