import { useCategoriesClock } from "context/CategoriesClock";
import { useMemo } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ClearIcon from "assets/images/ClearIcon";
import { Country } from "types/country.types";
import { AutocompleteType } from "types/autocomplete.types";
import { countriesList } from "utils/list";
/** @jsxImportSource @emotion/react */
import { theme } from "twin.macro";

const InputSearch = ({
    value,
    name,
    autoCompleteStyles,
    placeholder
}: AutocompleteType) => {
    const { setCountryFilters } = useCategoriesClock();

    return useMemo(
        () => (
            <Autocomplete
                clearIcon={<ClearIcon />}
                autoHighlight
                options={countriesList}
                onChange={(_, value, reason) =>
                    setCountryFilters({
                        value: reason === "clear" ? null : value?.name,
                        name
                    })
                }
                value={value ? { name: value } : (null as any)}
                sx={autoCompleteStyles}
                getOptionLabel={(option) => option.name || ""}
                renderOption={(props: any, option: Country) => (
                    <button
                        {...props}
                        style={{
                            color: theme`colors.blue.800`,
                            display: "flex",
                            fontSize: "0.9rem",
                            fontWeight: 400,
                            gap: "1rem",
                            width: "100%",
                            textAlign: "left"
                        }}
                    >
                        {option.file_url ? (
                            <img
                                loading="lazy"
                                width="16"
                                src={option.file_url}
                                alt={option.name}
                            />
                        ) : (
                            <span style={{ width: "1rem" }}></span>
                        )}
                        {option.name}
                    </button>
                )}
                renderInput={(params) => {
                    const activeCountryFlag = countriesList.find(
                        ({ properties }: any) =>
                            properties.name === params.inputProps.value
                    );

                    return (
                        <div tw="flex items-center relative">
                            {activeCountryFlag && (
                                <img
                                    key={params.inputProps.value + "'s flag"}
                                    loading="lazy"
                                    width="24"
                                    src={activeCountryFlag.file_url}
                                    alt="Country flag"
                                    tw="absolute left-4 z-10"
                                />
                            )}
                            <TextField
                                {...params}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password"
                                }}
                                sx={{
                                    textAlign: "left",
                                    paddingLeft: params.inputProps.value
                                        ? "2rem"
                                        : 0,
                                    paddingRight: "5rem",
                                    input: {
                                        "&::placeholder": {
                                            color: "#13173D !important",
                                            fontSize: "0.9rem",
                                            fontWeight: 600
                                        }
                                    }
                                }}
                                placeholder={placeholder}
                            />
                        </div>
                    );
                }}
            />
        ),
        [autoCompleteStyles, value, name, setCountryFilters, placeholder]
    );
};

export default InputSearch;
