import { t } from "i18next";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const About = () => (
    <div tw="flex text-blue-800 flex-col gap-[1.75rem] lg:px-16 md:px-4">
        <h2 tw="font-semiBold text-xxl sm:text-xl">{t("about_us.title")}</h2>
        <p tw="text-[1.068rem] sm:text-sm">{t("about_us.paragraph_one")}</p>
        <p tw="text-[1.068rem] sm:text-sm">{t("about_us.paragraph_two")}</p>
        <p tw="text-[1.068rem] sm:text-sm">{t("about_us.paragraph_three")}</p>
        <p tw="text-[1.068rem] sm:text-sm">{t("about_us.paragraph_four")}</p>
        <p tw="text-[1.068rem] sm:text-sm">{t("about_us.paragraph_five")}</p>
    </div>
);

export default About;
