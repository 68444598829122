import { t } from "i18next";
import selectedIcon from "assets/images/selectedIcon.svg";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Actions = ({ toggleState, handleToggleState }: any) => (
    <>
        <button
            tw="flex items-center border border-blue-800 justify-center text-blue-800 bg-[transparent] w-full max-w-[17.5rem] py-[0.6rem] px-[1rem] rounded-l-full text-sm font-semiBold gap-[0.5rem]"
            onClick={() =>
                toggleState.isAboutUsActive &&
                handleToggleState("isAboutUsActive")
            }
            css={[
                !toggleState.isAboutUsActive
                    ? tw`text-white bg-blue-800`
                    : tw`hover:bg-green-100`
            ]}
        >
            <img
                src={selectedIcon}
                alt="Selected icon"
                css={[toggleState.isAboutUsActive ? tw`hidden` : tw`block`]}
            />
            {t("about_us.title")}
        </button>
        <button
            tw="flex items-center border border-blue-800 justify-center text-blue-800 bg-[transparent] w-full max-w-[17.5rem]py-[0.6rem] px-[1rem] rounded-r-full text-sm font-semiBold gap-[0.5rem]"
            onClick={() =>
                !toggleState.isAboutUsActive &&
                handleToggleState("isAboutUsActive")
            }
            css={[
                toggleState.isAboutUsActive
                    ? tw`text-white bg-blue-800`
                    : tw`hover:bg-green-100`
            ]}
        >
            <img
                src={selectedIcon}
                alt="Selected icon"
                css={[!toggleState.isAboutUsActive && tw`hidden`]}
            />
            {t("methodology.title")}
        </button>
    </>
);

export default Actions;
