import { t } from "i18next";
import { autoCompleteStyles } from "utils/autoComplete";
import { useCategoriesClock } from "context/CategoriesClock";
import InputSearch from "components/Inputs/Search";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Filters = () => {
  const { countries } = useCategoriesClock();

  return (
    <article tw="grid grid-cols-4 pt-[1.5rem] md:flex-col gap-6 md:flex md:m-0 md:gap-4 md:w-[calc(100% - 2rem)] ml-[64px]">
      <div></div>
      <div tw="relative border border-blue-800 rounded-full hover:bg-green-50 py-[0.3rem] overflow-hidden">
        <InputSearch
          value={countries.comparedFirst}
          name="comparedFirst"
          placeholder={t("Search")}
          autoCompleteStyles={autoCompleteStyles}
        />
      </div>
      <div tw="relative border border-blue-800 rounded-full hover:bg-green-50 py-[0.3rem] overflow-hidden">
        <InputSearch
          value={countries.comparedSecond}
          name="comparedSecond"
          placeholder={t("Search")}
          autoCompleteStyles={autoCompleteStyles}
        />
      </div>
      <div tw="relative border border-blue-800 rounded-full hover:bg-green-50 py-[0.3rem] overflow-hidden">
        <InputSearch
          value={countries.comparedLast}
          name="comparedLast"
          placeholder={t("Search")}
          autoCompleteStyles={autoCompleteStyles}
        />
      </div>
    </article>
  );
};

export default Filters;
