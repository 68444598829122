import { t } from "i18next";
import { categoriesTableHeads } from "utils/list";
import DynamicSvg from "components/DynamicSvg/DynamicSvg";
import { useCategoriesClock } from "context/CategoriesClock";
import { getCountryInfo } from "utils/countries";
import useWindowResize from "hooks/useWindowResize";
import { CountryDataTypes } from "types/data.types";
import { urls } from "config/urls";
import useFetch from "hooks/useFetch";
import Loader from "components/Loader";
import { handleCategoriesRank } from "utils/utilities";
import Population from "components/Main/CountryComparison/Table/Population";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Table = () => {
  const { year, countries } = useCategoriesClock();
  const { isDesktop } = useWindowResize();

  const {
    data: firstComparedCountryData,
    isLoading: firstIsLoading,
  }: { data: CountryDataTypes; isLoading: boolean } = useFetch(
    `${urls.API_HOST_URL}countries/${
      getCountryInfo(countries.comparedFirst, true)?.iso3c
    }/ranking/category?year=${year}&measure=TOTAL_SPENDING&mapTypeFilter=GLOBAL`,
    countries.comparedFirst === "" || countries.comparedFirst === null
  );

  const {
    data: secondComparedCountryData,
    isLoading: secondIsLoading,
  }: { data: CountryDataTypes; isLoading: boolean } = useFetch(
    `${urls.API_HOST_URL}countries/${
      getCountryInfo(countries.comparedSecond, true)?.iso3c
    }/ranking/category?year=${year}&measure=TOTAL_SPENDING&mapTypeFilter=GLOBAL`,
    countries.comparedSecond === "" || countries.comparedSecond === null
  );

  const {
    data: thirdComparedCountryData,
    isLoading: thirdIsLoading,
  }: { data: CountryDataTypes; isLoading: boolean } = useFetch(
    `${urls.API_HOST_URL}countries/${
      getCountryInfo(countries.comparedLast, true)?.iso3c
    }/ranking/category?year=${year}&measure=TOTAL_SPENDING&mapTypeFilter=GLOBAL`,
    countries.comparedLast === "" || countries.comparedLast === null
  );

  return (
    <article
      tw="border border-blue-800 rounded-[0.6rem] w-full overflow-auto mt-4 h-[28.3rem] md:overflow-x-auto overscroll-contain relative md:mr-8 md:w-auto"
    >
      {!firstComparedCountryData &&
      !secondComparedCountryData &&
      !thirdComparedCountryData ? (
        <Loader />
      ) : (
        <div tw="md:w-[70rem] w-full text-[1.068rem] lg:text-[0.8rem]">
          <Population />
          <div tw="flex flex-row">
            <div tw="sticky left-0 z-50 flex flex-col">
              {categoriesTableHeads.map(({name}) => (
                  <div
                      tw="even-of-type:bg-green-0 odd-of-type:bg-white flex justify-start items-center gap-4 px-[0.75rem] min-h-[4.5rem] border-r-0 border-r-grey-200"
                      key={name}>
                    <DynamicSvg iconName={name} size={isDesktop ? 40 : 32}/>
                  </div>
              ))}
            </div>
            <div tw="grid grid-cols-4"
            >
              <div tw="flex flex-col">
                {categoriesTableHeads.map(({name}) => (
                    <div
                        tw="even-of-type:bg-green-0 flex justify-start items-center gap-4 px-[0.75rem] h-auto min-h-[4.5rem] border-r border-r-grey-200"
                        key={name}>
                      <p>{t(`country_comparison.${name}`)}</p>
                    </div>
                ))}
              </div>
              <div
                  tw="bg-green-0 relative flex flex-col xl:gap-1 md:gap-0 border-r border-r-grey-200"
                  css={[countries.comparedFirst === null && tw`bg-white`]}>
                {countries.comparedFirst === null ? null : firstIsLoading ? (
                    <Loader/>
                ) : (
                    handleCategoriesRank(firstComparedCountryData).map(
                        ({rank, code}) => (
                            <div
                                key={code}
                                tw="flex justify-center items-center md:text-sm px-[1.5rem] h-auto min-h-[4.5rem] text-[1.068rem] font-semiBold text-blue-800 even-of-type:bg-green-0 odd-of-type:bg-white">
                              <p>{rank}°</p>
                            </div>
                        )
                    )
                )}
              </div>
              <div
                  tw="bg-green-0 flex relative flex-col xl:gap-1 md:gap-0 border-r border-r-grey-200"
                  css={[countries.comparedSecond === null && tw`bg-white`]}>
                {countries.comparedSecond === null ? null : secondIsLoading ? (
                    <Loader/>
                ) : (
                    handleCategoriesRank(secondComparedCountryData).map(
                        ({rank, code}) => (
                            <div
                                key={code}
                                tw="flex justify-center items-center md:text-sm px-[1.5rem] h-auto min-h-[4.5rem] text-[1.068rem] font-semiBold text-blue-800 even-of-type:bg-green-0 odd-of-type:bg-white">
                              <p>{rank}°</p>
                            </div>
                        )
                    )
                )}
              </div>
              <div
                  tw="bg-green-0 flex relative flex-col xl:gap-1 md:gap-0 border-r border-r-grey-200"
                  css={[countries.comparedLast === null && tw`bg-white`]}>
                {countries.comparedLast === null ? null : thirdIsLoading ? (
                    <Loader/>
                ) : (
                    handleCategoriesRank(thirdComparedCountryData).map(
                        ({rank, code}) => (
                            <div
                                key={code}
                                tw="flex justify-center items-center md:text-sm px-[1.5rem] h-auto min-h-[4.5rem] text-[1.068rem] font-semiBold text-blue-800 even-of-type:bg-green-0 odd-of-type:bg-white">
                              <p>{rank}°</p>
                            </div>
                        )
                    )
                )}
              </div>
            </div>
          </div>

        </div>
      )}
    </article>
  );
};

export default Table;
