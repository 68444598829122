import { useCategoriesClock } from "context/CategoriesClock";
import { t } from "i18next";
import { years, categoriesList } from "utils/list";
import close from "assets/images/closeMenu.svg";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const MobileModal = () => {
    const { mobileModalDropdown, setFilters, setMobileModalDropdown } =
        useCategoriesClock();

    const handleItems = (items: any, activeSection: string) =>
        items?.map((item: any, i: number) => (
            <li id={item.name} key={i}>
                <button
                    disabled={item.disabled}
                    onClick={() => {
                        setMobileModalDropdown({
                            value: false,
                            name: "mobileModalDropdown",
                            section: mobileModalDropdown.section
                        });
                        setFilters({
                            value:
                                activeSection === "year"
                                    ? item.label
                                    : item.id || item.label,
                            name: activeSection
                        });
                        setFilters({
                            value: "categories",
                            name: null
                        });
                    }}
                    css={[
                        tw`flex gap-[1rem] items-center w-full justify-center`,
                        item.disabled && tw`pointer-events-none`
                    ]}
                >
                    {t(item.name)}
                </button>
            </li>
        ));

    const handleTemplate = (section: string) => {
        switch (section && section) {
            case "years":
                return handleItems(years, "year");
            case "categories":
                return handleItems(categoriesList, "categories");
            default:
                return <p>{t(`${section}.text`)}</p>;
        }
    };

    return (
        <aside
            css={[
                tw`rounded-[0.25rem 0.25rem 0 0] p-[1rem] fixed transition duration-500 ease-in-out left-0 bg-white shadow w-full opacity-0 bottom-0 translate-y-full z-[1003] flex flex-col gap-[1rem] rounded-tr-[1rem] rounded-tl-[1rem]`,
                mobileModalDropdown.isMobileModalDropdownOpen &&
                    tw`opacity-100 translate-y-0 text-blue-800`
            ]}
        >
            <div tw="flex justify-between items-center">
                <h3 tw="text-sm font-semiBold">
                    {t(`${mobileModalDropdown.section}`)}
                </h3>
                <button
                    onClick={() =>
                        setMobileModalDropdown({
                            value: false,
                            name: "mobileModalDropdown",
                            section: mobileModalDropdown.section
                        })
                    }
                >
                    <img src={close} alt="Close" />
                </button>
            </div>
            <ul
                className="scrollable"
                tw="flex flex-col gap-[2rem] max-h-[18.2rem] h-full overflow-y-auto"
            >
                {handleTemplate(mobileModalDropdown.section!)}
            </ul>
        </aside>
    );
};

export default MobileModal;
