import { t } from "i18next";
import seeMoreIcon from "assets/images/seeMore.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Methodology = () => (
    <div tw="flex flex-col gap-[1.1rem] text-blue-800 lg:px-16 md:px-4">
        <h2 tw="font-semiBold text-xxl sm:text-xl">{t("methodology.title")}</h2>
        <p tw="text-[1.068rem] sm:text-sm">{t("methodology.paragraph_one")}</p>
        <p tw="text-[1.068rem] sm:text-sm">{t("methodology.paragraph_two")}</p>
        <ol tw="flex flex-col gap-2 pl-12 list-[revert]">
            <li tw="text-[1.068rem] sm:text-sm">
                {t("methodology.paragraph_three")}
            </li>
            <li tw="text-[1.068rem] sm:text-sm">
                {t("methodology.paragraph_four")}
            </li>
            <li tw="text-[1.068rem] sm:text-sm">
                {t("methodology.paragraph_five")}
            </li>
            <li tw="text-[1.068rem] sm:text-sm">
                {t("methodology.paragraph_six")}
            </li>
        </ol>
        <a
            href="/methodology.pdf"
            target="_blank"
            tw="w-[15.313rem] rounded-full border border-blue-800 flex gap-4 items-center text-sm font-semiBold p-[0.6rem 1.5rem] sm:w-full justify-center hover:bg-green-100"
        >
            {t("methodology.see_full_methodology")}
            <img src={seeMoreIcon} alt="See more" />
        </a>
    </div>
);

export default Methodology;
