import { t } from "i18next";
import { meet_wdl_list } from "utils/list";
import boldCheck from "assets/images/boldCheck.svg";
import learnMore from "assets/images/learnMore.svg";
import DynamicSvg from "components/DynamicSvg/DynamicSvg";
import ResponsiveImage from "components/ResponsiveImagePC";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const WorldDataPro = () => (
  <section
    tw="text-white bg-blue-800 p-[4rem 2rem 4rem 4rem] md:p-[2rem 1rem] flex justify-center"
    id="worldDatapro">
    <div tw="grid grid-cols-[35% 1fr] lg:flex lg:flex-col lg:gap-12 sm:gap-4 gap-4 text-white max-w-[90rem] m-auto">
      <article tw="flex flex-col gap-4 justify-center text-[1.063rem] md:text-sm">
        <h2 tw="text-green-400 font-semiBold text-xxl md:text-xl">
          {t("meet_world_data_pro")}
        </h2>
        <p tw="font-medium">{t("wdp_description")}</p>
        <ul tw="ml-4 sm:ml-2 flex flex-col gap-4">
          {meet_wdl_list.map((item) => (
            <li
              key={item}
              tw="flex items-center font-semiBold text-[1.063rem] gap-2">
              <img src={boldCheck} alt="Check" />
              {t(item)}
            </li>
          ))}
        </ul>
        <a
          href="https://dashboard.worlddata.pro/freemium/register"
          tw="mt-[2rem] sm:hidden flex justify-center items-center gap-3 border border-green-400 rounded-full text-green-400 py-[0.6rem] w-[13.625rem] bg-blue-800 font-bold text-sm hover:bg-green-50 hover:text-blue-800">
          {t("learn_more")}
          <img src={learnMore} alt="Export" />
        </a>
      </article>

      <a href="https://dashboard.worlddata.pro/freemium/register">
        <ResponsiveImage />
      </a>
      <a
        href="https://dashboard.worlddata.pro/freemium/register"
        tw="sm:flex hidden mt-2 w-full justify-center items-center gap-3 border border-green-400 rounded-full text-green-400 py-[0.6rem] bg-blue-800 font-bold text-sm hover:bg-green-50 hover:text-blue-800">
        {t("learn_more")}
        <DynamicSvg iconName="learnMore" primaryColor="#CCFF00" />
        <img src={learnMore} alt="Export" />
      </a>
    </div>
  </section>
);

export default WorldDataPro;
