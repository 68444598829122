import Desktop from "components/Header/NavBar/Desktop";
import Mobile from "components/Header/NavBar/Mobile";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Menu = ({ handleToggleState, toggleState }: any) => (
    <>
        <Desktop />
        <Mobile
            handleToggleState={handleToggleState}
            toggleState={toggleState}
        />
    </>
);

export default Menu;
