const ResponsiveImage = () => (
  <img
    src="assets/images/freemium-small.png"
    srcSet="
    assets/images/freemium-small.png  500w, 
    assets/images/freemium-small-2x.png  1000w, 
    assets/images/freemium-big.png  800w,
    assets/images/freemium-big-2x.png 1600w"
    sizes="(max-width: 500px) 500px, 
         (min-width: 501px) and (max-width: 800px) 800px, 
         100vw"
    alt="World Data Pro Frremium"
    loading="lazy"
    style={{ width: "100%", height: "auto" }}
  />
);

export default ResponsiveImage;
