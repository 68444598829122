import { t } from "i18next";
import { legendList } from "utils/list";
import { useCategoriesClock } from "context/CategoriesClock";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Legend = () => {
  const { countries, countryLevel, setFilters } = useCategoriesClock();

  return (
      <div css={[tw`bg-green-0 p-4 min1441:pl-[23%]`, countries.mapCountry && tw`md:hidden`]}>
        <p tw="text-regular text-blue-800 pt-2 font-semiBold leading-6 pb-4">{t("legend_heading")}</p>
        <ul>
          {legendList.map(({ id, color }) => (
            <li
              css={[
                countryLevel !== id && tw`opacity-20`,
                !countryLevel && tw`opacity-100`,
                countryLevel && tw`pointer-events-none`,
              ]}
              key={id}
              onMouseEnter={() => setFilters({ name: "hoveredLegend", value: id })}
              onMouseLeave={() =>
                setFilters({ name: "hoveredLegend", value: null })
              }>
              <button tw="flex items-center gap-4 text-blue-800 text-sm py-2 w-full">
                <span
                  tw="w-4 h-4 block rounded-full border border-blue-800"
                  style={{ background: color }}></span>
                {t(id)}
              </button>
            </li>
          ))}
        </ul>
      </div>
  );
};

export default Legend;
