import { t } from "i18next";
import { links } from "utils/list";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Desktop = () => (
  <nav tw="lg:hidden flex justify-end items-center">
    <ul tw="flex justify-between items-center gap-[1.5rem] xl:gap-[1rem]">
      {links.map(({ id, name }) => (
        <li key={id}>
          <a
            href={id}
            tw="text-blue-800 text-[0.813rem] focus:bg-green-100 focus:font-semiBold hover:bg-green-100 hover:font-semiBold focus:border-b focus:border-b-blue-800 p-[1.4rem] xl:p-[1.7rem 0.5rem]">
            {t(name)}
          </a>
        </li>
      ))}
    </ul>
  </nav>
);

export default Desktop;
