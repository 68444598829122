import { urls } from "config/urls";
import { useCategoriesClock } from "context/CategoriesClock";
import { useCallback, useState, useEffect } from "react";
import {
  beginningCurrentYear,
  getCategoryCode,
  roundToThousands,
  secondsPerYear,
} from "utils/utilities";
import useFetch from "./useFetch";
import { HeroSectionDataTypes } from "types/data.types";

const useTicking = (value?: number) => {
  const { category, year } = useCategoriesClock();

  const { data }: { data: HeroSectionDataTypes } = useFetch(
    `${urls.API_HOST_URL}main-hero?year=${Number(year) - 1}${
      category !== "all_categories"
        ? `&categoryCode=${getCategoryCode(category)}`
        : ""
    }`
  );

  const handleTickingValue = useCallback((): number => {
    const now = Math.round(Date.now() / 1000);
    const secondsUntilNow = now - beginningCurrentYear;
    const currentYearAlreadySpent = value! - data?.alreadySpent;
    const currentYearAlreadySpentPerSeconds =
      currentYearAlreadySpent / secondsPerYear;
    const currentYearAlreadySpentPerSecondsUntilNow =
      secondsUntilNow * currentYearAlreadySpentPerSeconds;
    return currentYearAlreadySpentPerSecondsUntilNow;
  }, [value, data?.alreadySpent]);

  const currentCoverage = !value ? "" : handleTickingValue();
  const [tickingValue, setTickingValue] = useState(currentCoverage);

  useEffect(() => {
    const interval = setInterval(() => {
      setTickingValue(roundToThousands(value! + handleTickingValue()));
    }, 1000);

    return () => clearInterval(interval);
  }, [handleTickingValue, value]);

  return { tickingValue };
};

export default useTicking;
