import { useCallback } from "react";
import { centerMap, defaultMapZoom } from "utils/leaflet";

const useLeaflet = (mapRef: any, country: string | null) => {
    const handleMapResize = useCallback(() => {
        if (!country) {
            mapRef?.target.invalidateSize();
            mapRef?.target.flyTo(centerMap, defaultMapZoom);
        }
    }, [mapRef?.target, country]);

    return { handleMapResize };
};

export default useLeaflet;
