import { useCategoriesClock } from "context/CategoriesClock";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Category = () => {
  const { category } = useCategoriesClock();

  return (
    <div tw="relative md:hidden h-[calc(100% - 12.5rem)] lg:h-full flex items-center justify-center">
      <div className="overlay" tw="absolute w-full h-full z-10"></div>
      <video
        src={`assets/videos/${category}.mp4`}
        autoPlay
        loop
        muted
        playsInline
        tw="w-full h-full object-cover"></video>
      <img
        tw="absolute z-20 w-full p-[20%]"
        src={`assets/images/${category}.svg`}
        alt={category}
      />
    </div>
  );
};

export default Category;
