import { MouseEvent, useCallback, useState } from "react";

interface Navbar {
    isNavbarOpened: boolean;
}

interface AboutUs {
    isAboutUsActive: boolean;
}

type CombinedTypes = Navbar & AboutUs;

const useToggle = () => {
    const [toggleState, setToggleState] = useState<CombinedTypes>({
        isNavbarOpened: false,
        isAboutUsActive: false
    });

    const handleToggleState = useCallback(
        (toggleType: string, e?: MouseEvent) => {
            if (e) e.stopPropagation();
            setToggleState((prevState) => {
                const key = toggleType as string as keyof typeof toggleState;
                return { ...toggleState, [toggleType]: !prevState[key] };
            });
        },
        [toggleState, setToggleState]
    );

    return { handleToggleState, toggleState };
};

export default useToggle;
