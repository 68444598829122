import Filters from "components/Main/Map/Filters";
import Card from "components/Main/Map/Card";
import Actions from "components/Main/Map/Actions";
import Category from "components/Main/Map/Category";
import GeoJSON from "components/Main/Map/GeoJson";
import YearSlider from "components/YearSlider";
import Mosaic from "components/Main/Map/Mosaic";
import { useCategoriesClock } from "context/CategoriesClock";
import { urls } from "config/urls";
import useFetch from "hooks/useFetch";
import { MapSectionDataTypes } from "types/data.types";
import Loader from "components/Loader";
import { getCategoryCode } from "utils/utilities";
import { countriesList } from "utils/list";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Map = () => {
  const { countries, category, measure, year, isMapViewActive } =
    useCategoriesClock();

  const { data, isLoading }: { data: MapSectionDataTypes; isLoading: boolean } =
    useFetch(
      `${urls.API_HOST_URL}map?year=${year}&measureFilter=${measure}${
        category !== "all_categories"
          ? `&categoryCode=${getCategoryCode(category)}`
          : ""
      }`
    );

  const mapData = countriesList.map((country: any) => {
    const findedItem = data?.find(
      ({ countryCode }: { countryCode: string }) => countryCode === country.id
    );

    return { ...findedItem, ...country };
  });

  return (
    <section
      css={[
        tw`grid lg:grid-cols-2 md:grid-cols-1 lg:h-full relative w-full `,
        (!isMapViewActive && category !== "all_categories") ||
        (isMapViewActive && countries.mapCountry)
          ? tw`grid-cols-[35% auto 40%]`
          : tw`grid-cols-[35% 1fr] md:flex md:flex-col`,
      ]}
      id="map">
      <Filters />
      <article
        css={[
          tw`flex-1 md:flex-auto relative`,
          (!isMapViewActive && category !== "all_categories") ||
          (isMapViewActive && countries.mapCountry)
            ? tw`md:hidden`
            : tw`md:flex md:h-[30rem]`,
        ]}>
        <div tw="flex w-full p-[2rem 1rem 2rem] lg:hidden justify-center">
          <Actions />
        </div>
        {isMapViewActive ? (
          isLoading ? (
            <Loader />
          ) : (
            <GeoJSON mapData={mapData} />
          )
        ) : category !== "all_categories" ? (
          <Category />
        ) : (
          <Mosaic />
        )}
      </article>
      {(!isMapViewActive && category !== "all_categories") ||
      (isMapViewActive && countries.mapCountry) ? (
        <Card spendingLevelData={mapData} />
      ) : null}
      {(isMapViewActive || category !== "all_categories") && (
        <div tw="absolute bottom-[-1rem] right-0 w-[65%] left-[35%] lg:hidden ">
          <YearSlider isFromMap={true} />
        </div>
      )}
    </section>
  );
};

export default Map;
