import { t } from "i18next";
import arrow from "assets/images/map/arrow.svg";
import { TooltipInfoProps } from "types/tooltip.types";
import { getCountryInfo } from "utils/countries";
/** @jsxImportSource @emotion/react */
import "twin.macro";
import { getColor } from "utils/leaflet";

const Tooltip = ({ tooltipInfo }: TooltipInfoProps) => (
  <article
    tw="md:hidden bg-blue-800 flex flex-col gap-6 p-4 w-full max-w-max fixed z-[1001] text-white shadow"
    style={{
      left: `${tooltipInfo?.originalEvent?.clientX + 10}px`,
      top: `${tooltipInfo?.originalEvent?.clientY + 10}px`,
    }}>
    <div tw="flex items-center gap-4">
      <img
        width={32}
        src={
          getCountryInfo(tooltipInfo.target.feature.properties.name, true)
            ?.file_url
        }
        alt="Flag"
      />
      <h3 tw="text-lg font-medium">
        {tooltipInfo.target.feature.properties.name}
      </h3>
    </div>
    <div tw="text-sm flex justify-between text-center items-center gap-4">
      <h4>{t("total_spending")}</h4>
      <span
        tw="font-semiBold p-2 w-auto"
        style={{
          color: getColor(tooltipInfo.target.feature.spendingLevel)?.text,
          background: getColor(tooltipInfo.target.feature.spendingLevel)?.color,
        }}>
        {t(tooltipInfo.target.feature.spendingLevel).replace("spending", "")}
      </span>
    </div>
    <div tw="flex items-center gap-4 text-green-400 font-medium text-sm">
      <h4>{t("click_to_explore")}</h4>
      <img src={arrow} alt="Arrow" />
    </div>
  </article>
);

export default Tooltip;
