import { createContext, useContext, useReducer } from "react";
import { globalStateReducer } from "reducers/globalState";
import {
    ActiveFiltersDispatchProps,
    ChildrenProps,
    GlobalState
} from "types/categories-clock-context.types";
import { currentYear } from "utils/utilities";

const initialState: GlobalState = {
    countries: {
        mapCountry: "",
        comparedFirst: "United States of America",
        comparedSecond: "China",
        comparedLast: "India"
    },
    countryLevel: null,
    countryNational: "GLOBAL",
    countryOverview: "category",
    category: "all_categories",
    hoveredLegend: null,
    measure: "TOTAL_SPENDING",
    isMapViewActive: true,
    mobileModalDropdown: {
        isMobileModalDropdownOpen: false,
        section: null
    },
    year: currentYear.toString(),
    setMobileModalDropdown: () => {},
    setFilters: () => {},
    setCountryFilters: () => {}
};

const CategoriesClockContext = createContext(initialState);

export const CategoriesClockContextProvider = ({ children }: ChildrenProps) => {
    const [state, dispatch] = useReducer(globalStateReducer, initialState);

    const setFilters = ({ name, value }: ActiveFiltersDispatchProps) =>
        dispatch({
            payload: { name, value },
            type: "SELECT_FILTERS"
        });

    const setMobileModalDropdown = ({
        name,
        value,
        section,
        data
    }: ActiveFiltersDispatchProps) =>
        dispatch({
            payload: { name, value, section, data },
            type: "SELECT_FILTERS_MODAL"
        });

    const setCountryFilters = ({ name, value }: ActiveFiltersDispatchProps) =>
        dispatch({
            payload: { name, value },
            type: "SELECT_COUNTRY_FILTERS"
        });

    const value = {
        countries: {
            ...state.countries
        },
        countryNational: state.countryNational,
        countryOverview: state.countryOverview,
        countryLevel: state.countryLevel,
        category: state.category,
        measure: state.measure,
        hoveredLegend: state.hoveredLegend,
        isMapViewActive: state.isMapViewActive,
        mobileModalDropdown: {
            ...state.mobileModalDropdown
        },
        year: state.year,
        setMobileModalDropdown,
        setFilters,
        setCountryFilters
    };

    return (
        <CategoriesClockContext.Provider value={value}>
            {children}
        </CategoriesClockContext.Provider>
    );
};

export const useCategoriesClock = () => {
    const context = useContext(CategoriesClockContext);
    if (context === undefined)
        throw new Error(
            "useCategoriesClock needs to be within the CategoriesClockContext"
        );

    return context;
};
