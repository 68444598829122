import { t } from "i18next";
import logo from "assets/images/footerLogo.svg";
import ContactUs from "components/Footer/ContactUs";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Footer = () => (
  <footer tw="lg:mb-[6.5rem] bg-blue-900 text-green-400 p-[4rem] md:p-[2rem 1rem] flex sm:flex-col items-start justify-start w-full">
    <div tw="flex flex-row sm:flex-col gap-16 sm:gap-[4rem] max-w-[90rem] m-auto w-full md:text-center md:m-auto">
      <div tw="md:text-center md:m-auto">
        <h2 tw="max-w-[20rem] font-semiBold text-[2.25rem] md:text-[1.5rem] md:leading-[1.5rem] uppercase sm:pb-[1rem]">
          {t("title")}
        </h2>
        <span tw="text-[1.063rem]">{t("footer_by")}</span>
        <div tw="w-[13.088rem] uppercase flex justify-between items-end pt-[0.5rem] md:m-auto">
          <img tw="w-[3.708rem] h-[2.825rem]" alt="WDL" src={logo} />
          <span>{t("footer_world_data_lab")}</span>
        </div>
      </div>
      <ContactUs />
    </div>
  </footer>
);

export default Footer;
