import { useState, useEffect } from "react";

const useWindowResize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const isDesktop = width > 1024;
  const isTablet = width >= 768;
  const isMobile = width < 768;

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { isDesktop, isTablet, isMobile };
};

export default useWindowResize;
