import { t } from "i18next";
import { countriesList } from "utils/list";

export const getCountryInfo = (countryNameId: string, isByName: boolean) => {
    let countryInfo = countriesList.find((country: any) => {
        return (isByName ? country.properties.name : country.id) === countryNameId;
    });
    // If the first search did not yield a result and the search was by name, perform a secondary search
    if (!countryInfo && isByName) {
        countryInfo = countriesList.find((country: any) => {
            return country.name === countryNameId; // Secondary search directly on country.name without accessing properties
        });
    }
    return countryInfo;
};

export const handleTitle = (countryOverview: string, countryNational: string) =>
    countryOverview === "category"
        ? t(`spending_${countryNational}`)
        : countryOverview === "spending"
        ? t(`total_${countryNational}`)
        : t("overview_info");
