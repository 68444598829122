import { t } from "i18next";
import logo from "assets/images/logo.svg";
import burgerMenu from "assets/images/burgerMenu.svg";
import close from "assets/images/close.svg";
import Menu from "components/Header/NavBar";
import useToggle from "hooks/useToggle";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import useWindowResize from "hooks/useWindowResize";

const Header = () => {
  const { handleToggleState, toggleState } = useToggle();
  const { isDesktop } = useWindowResize();

  return (
    <header
      css={[
        tw`w-full m-auto flex items-center justify-between p-[1rem 4rem] xl:p-[1rem 2rem] md:p-[1rem] gap-[0 3rem] bg-white box-border fixed top-0 z-[1001] right-0 left-0 shadow-[0 0.3rem 0.3rem 0 rgba(0, 0, 0, 0.10)]`,
        toggleState.isNavbarOpened && !isDesktop && tw`bg-green-400`,
      ]}>
      <div tw="flex items-center justify-between w-full max-w-[90rem] m-auto gap-12">
        <div tw="flex items-center gap-[1rem]">
          <img src={logo} alt="Categories Clock" />
          <h1 tw="font-regular text-[0.625rem] text-blue-800">
            {t("title")}
            <span tw="text-green-700 block">by {t("sub_title")}</span>
          </h1>
        </div>
        <button
          tw="lg:block hidden"
          onClick={() => handleToggleState("isNavbarOpened")}>
          {toggleState.isNavbarOpened ? (
            <img src={close} alt="Close" />
          ) : (
            <img src={burgerMenu} alt="Menu" />
          )}
        </button>
        <Menu handleToggleState={handleToggleState} toggleState={toggleState} />
      </div>
    </header>
  );
};

export default Header;
