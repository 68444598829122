import { t } from "i18next";
import Filters from "components/Main/CountryComparison/Filters";
import Table from "components/Main/CountryComparison/Table";
import YearSlider from "components/YearSlider";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const CountryComparison = () => (
  <section
    id="comparison"
    tw="text-blue-800 m-[4rem auto 2rem] md:m-[1rem 0 2rem 1rem] max-w-[90rem] w-full px-16 md:p-0">
    <h2 tw="text-[2.25rem] font-semiBold pb-[0.8rem] md:text-xl leading-[2.25rem]">
      {t("country_comparison.title")}
    </h2>
    <p tw="text-[1.068rem] font-medium md:text-sm">
      {t("country_comparison.description")}
    </p>
    <Filters />
    <Table />
    <YearSlider isFromCountryComparison={true} />
  </section>
);

export default CountryComparison;
