import { useCategoriesClock } from "context/CategoriesClock";
import { countryOverviewList, countryNationalList } from "utils/list";
import { t } from "i18next";
import selectedIcon from "assets/images/selectedIcon.svg";
import InputSelect from "components/Inputs/Select";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Actions = () => {
    const {
        category,
        countryOverview,
        countryNational,
        setFilters,
        isMapViewActive
    } = useCategoriesClock();

    return (
        <>
            <div
                css={[
                    tw`grid grid-cols-3`,
                    !isMapViewActive && tw`grid-cols-2`
                ]}
            >
                {countryOverviewList.map(({ id, name }) => (
                    <button
                        key={id}
                        tw="flex items-center border border-blue-800 justify-center text-blue-800 bg-[transparent] w-full py-[0.6rem] px-[1rem] first-of-type:rounded-l-full last-of-type:rounded-r-full text-sm font-semiBold gap-[0.5rem]"
                        onClick={() => {
                            if (countryOverview !== id) {
                                setFilters({
                                    name: "countryNational",
                                    value: "GLOBAL"
                                });
                                setFilters({
                                    name: "countryOverview",
                                    value: id
                                });
                            }
                        }}
                        css={[
                            countryOverview === id
                                ? tw`text-white bg-blue-800`
                                : tw`hover:bg-green-100`,
                            !isMapViewActive && id === "category" && tw`hidden`,
                            !isMapViewActive &&
                                tw`[:nth-of-type(2)]:rounded-l-full`
                        ]}
                    >
                        <img
                            src={selectedIcon}
                            alt="Selected icon"
                            css={[
                                countryOverview !== id ? tw`hidden` : tw`block`
                            ]}
                        />
                        {t(name)}
                    </button>
                ))}
            </div>
            {countryOverview !== "overview" && (
                <div
                    css={[
                        tw`grid grid-cols-1 gap-4 items-center`,
                        countryOverview === "spending" && tw`grid-cols-2`,
                        !isMapViewActive && tw`grid-cols-1`
                    ]}
                >
                    {isMapViewActive && countryOverview === "spending" && (
                        <InputSelect
                            name="category"
                            value={category}
                            isFromMap={true}
                        />
                    )}
                    <div tw="flex h-[2.43rem] m-[0.5rem 0 1rem]">
                        {countryNationalList.map(({ id, name }) => (
                            <button
                                key={id}
                                css={[
                                    tw`flex items-center border border-blue-800 justify-center text-blue-800 bg-[transparent] w-full py-[0.6rem] px-[1rem] first-of-type:rounded-l-full last-of-type:rounded-r-full text-sm font-semiBold gap-[0.5rem] [:nth-of-type(2)]:rounded-r-full capitalize`,
                                    countryNational === id
                                        ? tw`text-white bg-blue-800`
                                        : tw`hover:bg-green-100`,
                                    countryOverview === "spending" &&
                                        id === "NATIONAL" &&
                                        tw`hidden`,
                                    isMapViewActive &&
                                        countryOverview === "category" &&
                                        id === "CONTINENTAL" &&
                                        tw`hidden`,
                                    !isMapViewActive &&
                                        id === "NATIONAL" &&
                                        tw`hidden`
                                ]}
                                onClick={() =>
                                    countryNational !== id &&
                                    setFilters({
                                        name: "countryNational",
                                        value: id
                                    })
                                }
                            >
                                <img
                                    src={selectedIcon}
                                    alt="Selected icon"
                                    css={[
                                        countryNational !== id
                                            ? tw`hidden`
                                            : tw`block`
                                    ]}
                                />
                                {t(name)}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default Actions;
