import { t } from "i18next";
import selectedIcon from "assets/images/selectedIcon.svg";
import { useCategoriesClock } from "context/CategoriesClock";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Actions = () => {
    const { isMapViewActive, setCountryFilters, setFilters } =
        useCategoriesClock();

    return (
        <>
            <button
                tw="flex items-center border border-blue-800 justify-center text-blue-800 bg-[transparent] w-full max-w-[17.5rem]py-[0.6rem] px-[1rem] rounded-l-full text-sm font-semiBold gap-[0.5rem] lg:border lg:border-white lg:text-white"
                onClick={() => {
                    !isMapViewActive &&
                        setFilters({
                            name: "isMapViewActive",
                            value: true
                        });
                    setCountryFilters({ name: "mapCountry", value: "" });
                    setFilters({
                        name: "countryOverview",
                        value: "category"
                    });
                    setFilters({
                        name: "countryNational",
                        value: "GLOBAL"
                    });
                }}
                css={[
                    isMapViewActive
                        ? tw`text-white bg-blue-800 lg:bg-blue-700`
                        : tw`hover:bg-green-100 lg:hover:bg-blue-700`
                ]}
            >
                <img
                    src={selectedIcon}
                    alt="Selected icon"
                    css={[!isMapViewActive && tw`hidden`]}
                />
                {t("map")}
            </button>
            <button
                tw="flex items-center border border-blue-800 justify-center text-blue-800 bg-[transparent] w-full max-w-[17.5rem]py-[0.6rem] px-[1rem] rounded-r-full text-sm font-semiBold gap-[0.5rem] lg:border lg:border-white lg:text-white"
                onClick={() => {
                    isMapViewActive &&
                        setFilters({
                            name: "isMapViewActive",
                            value: false
                        });
                    setCountryFilters({ name: "mapCountry", value: "" });
                    setFilters({
                        name: "countryOverview",
                        value: "spending"
                    });
                    setFilters({
                        name: "countryNational",
                        value: "GLOBAL"
                    });
                }}
                css={[
                    !isMapViewActive
                        ? tw`text-white bg-blue-800 lg:bg-blue-700`
                        : tw`hover:bg-green-100 lg:hover:bg-blue-700`
                ]}
            >
                <img
                    src={selectedIcon}
                    alt="Selected icon"
                    css={[isMapViewActive && tw`hidden`]}
                />
                {t("categories")}
            </button>
        </>
    );
};

export default Actions;
