import i18n from "i18next";
import en from "i18n/en.json";
import detector from "i18next-browser-languagedetector";
import TagManager from "react-gtm-module";
import { initReactI18next } from "react-i18next";
import "./App.css";
import CategoriesClock from "views/CategoriesClock";
import { CategoriesClockContextProvider } from "context/CategoriesClock";

const tagManagerArgs = {
    dataLayerName: "pageView",
    gtmId: "G-Q4ET8ZCX5H"
};

TagManager.initialize(tagManagerArgs);

i18n.use(initReactI18next)
    .use(detector)
    .init({
        supportedLngs: ["en"],
        lng: "en",
        resources: {
            en: {
                translation: en
            }
        },
        fallbackLng: "en",
        interpolation: {
            escapeValue: true
        }
    });

function App() {
    TagManager.dataLayer(tagManagerArgs);

    return (
        <CategoriesClockContextProvider>
            <CategoriesClock />
        </CategoriesClockContextProvider>
    );
}

export default App;
