import { t } from "i18next";
import { links, socialMediaLinks } from "utils/list";
import DynamicSvg from "components/DynamicSvg/DynamicSvg";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Mobile = ({ handleToggleState, toggleState }: any) => (
    <nav
        css={[
            tw`hidden lg:flex flex-col fixed w-full left-0 top-0 mt-[3.8rem] h-[calc(100% - 3.8rem)] z-[1004] justify-between translate-y-[calc(-100% - 3.8rem)] transition-transform duration-300 bg-green-400 text-blue-800`,
            toggleState.isNavbarOpened && tw`translate-y-0`
        ]}
    >
        <ul tw="px-[4rem] flex flex-col justify-evenly pb-[1rem] md:p-4 text-[1.063rem] font-semiBold h-full">
            {links.map(({ name, id }) => (
                <li
                    tw="border-b border-b-grey-300 py-4 flex-1 flex items-center justify-center"
                    key={id}
                    onClick={() => handleToggleState("isNavbarOpened")}
                >
                    <a href={id}>{t(name)}</a>
                </li>
            ))}
        </ul>
        <div tw="p-[1rem 4rem] md:p-[0.5rem 1rem] text-center">
            <h3 tw="tracking-[0.041rem] text-sm font-semiBold uppercase pb-[1rem]">
                {t("contact_us")}
            </h3>
            <a
                href="mailto:contact@worlddata.io"
                tw="text-[1.063rem] font-semiBold"
            >
                contact@worlddata.io
            </a>
            <ul tw="flex justify-between items-center gap-[1rem] pt-[3rem] pb-[1.5rem]">
                {socialMediaLinks.map(({ name, id }) => (
                    <li key={name}>
                        <a href={id}>
                            <DynamicSvg
                                iconName={name}
                                primaryColor="#000114"
                            />
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    </nav>
);

export default Mobile;
