import { DynamicSvgProps } from "types/dynamic-svg.types";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const DynamicSvg = ({ iconName, primaryColor, size }: DynamicSvgProps) => (
    <span>
        {
            {
                globe: (
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke={primaryColor}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M2 12H22"
                            stroke={primaryColor}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z"
                            stroke={primaryColor}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ),
                linkedIn: (
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M16 8C17.5913 8 19.1174 8.63214 20.2426 9.75736C21.3679 10.8826 22 12.4087 22 14V21H18V14C18 13.4696 17.7893 12.9609 17.4142 12.5858C17.0391 12.2107 16.5304 12 16 12C15.4696 12 14.9609 12.2107 14.5858 12.5858C14.2107 12.9609 14 13.4696 14 14V21H10V14C10 12.4087 10.6321 10.8826 11.7574 9.75736C12.8826 8.63214 14.4087 8 16 8V8Z"
                            stroke={primaryColor}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M6 9H2V21H6V9Z"
                            stroke={primaryColor}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M4 6C5.10457 6 6 5.10457 6 4C6 2.89543 5.10457 2 4 2C2.89543 2 2 2.89543 2 4C2 5.10457 2.89543 6 4 6Z"
                            stroke={primaryColor}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ),
                twitter: (
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g id="icon/twitter">
                            <path
                                id="Vector"
                                d="M23 2.99998C22.0424 3.67546 20.9821 4.19209 19.86 4.52999C19.2577 3.8375 18.4573 3.34668 17.567 3.12391C16.6767 2.90115 15.7395 2.95718 14.8821 3.28444C14.0247 3.6117 13.2884 4.19439 12.773 4.9537C12.2575 5.71302 11.9877 6.61232 12 7.52998V8.52998C10.2426 8.57555 8.50127 8.1858 6.93101 7.39543C5.36074 6.60506 4.01032 5.43862 3 3.99998C3 3.99998 -1 13 8 17C5.94053 18.398 3.48716 19.0989 1 19C10 24 21 19 21 7.49998C20.9991 7.22144 20.9723 6.94358 20.92 6.66999C21.9406 5.66348 22.6608 4.3927 23 2.99998V2.99998Z"
                                stroke={primaryColor}
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </g>
                    </svg>
                ),
                instagram: (
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_1017_5941)">
                            <path
                                d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z"
                                stroke={primaryColor}
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M16 11.37C16.1234 12.2022 15.9812 13.0522 15.5937 13.799C15.2062 14.5458 14.5931 15.1514 13.8416 15.5297C13.0901 15.9079 12.2384 16.0396 11.4077 15.9059C10.5771 15.7723 9.80971 15.3801 9.21479 14.7852C8.61987 14.1902 8.22768 13.4229 8.09402 12.5922C7.96035 11.7615 8.09202 10.9099 8.47028 10.1584C8.84854 9.40685 9.45414 8.79374 10.2009 8.40624C10.9477 8.01874 11.7977 7.87658 12.63 8C13.4789 8.12588 14.2648 8.52146 14.8716 9.1283C15.4785 9.73515 15.8741 10.5211 16 11.37Z"
                                stroke={primaryColor}
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1017_5941">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                ),
                $_spent_with_housing: (
                    <svg
                        width={size}
                        height={size}
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_996_862)">
                            <path
                                d="M43.6103 15.906V46.7992H4.39355L4.39355 15.906"
                                fill="#3B46B2"
                            />
                            <path
                                d="M43.6103 15.906V46.7992H4.39355L4.39355 15.906"
                                stroke="#3B46B2"
                                strokeWidth="0.6"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M13.7676 7.80097V2.49286H4.41992V13.8296"
                                fill="#3B46B2"
                            />
                            <path
                                d="M13.7676 7.80097V2.49286H4.41992V13.8296"
                                stroke="#3B46B2"
                                strokeWidth="0.6"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M37.0801 33.3864H27.7324V46.8002H37.0801V33.3864Z"
                                fill="#CCFF00"
                                stroke="#CCFF00"
                                strokeWidth="0.6"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                            />
                            <path
                                d="M20.2715 19.9724H10.9238V29.32H20.2715V19.9724Z"
                                fill="#CCFF00"
                                stroke="#CCFF00"
                                strokeWidth="0.6"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M20.2715 33.3864H10.9238V42.7339H20.2715V33.3864Z"
                                fill="#CCFF00"
                                stroke="#CCFF00"
                                strokeWidth="0.6"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M37.0801 19.9724H27.7324V29.32H37.0801V19.9724Z"
                                fill="#CCFF00"
                                stroke="#CCFF00"
                                strokeWidth="0.6"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M46.8012 15.906L24.0012 1.19998L1.20117 15.906"
                                fill="#2A3280"
                            />
                            <path
                                d="M46.8012 15.906L24.0012 1.19998L1.20117 15.906H4.39323H43.61H46.8012Z"
                                stroke="#2A3280"
                                strokeWidth="0.6"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_996_862">
                                <rect width="48" height="48" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                ),
                $_spent_with_communication: (
                    <svg
                        width={size}
                        height={size}
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M22.2015 3.60004H4.70041C2.76729 3.60004 1.2002 5.42832 1.2002 7.68362V40.3523C1.2002 42.6076 2.76729 44.4359 4.70041 44.4359H22.2015C24.1346 44.4359 25.7017 42.6076 25.7017 40.3523V7.68362C25.7017 5.42832 24.1346 3.60004 22.2015 3.60004Z"
                            fill="#3B46B2"
                            stroke="#3B46B2"
                            strokeWidth="0.600158"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <circle
                            cx="13.4532"
                            cy="40.7714"
                            r="1.63386"
                            fill="#CCFF00"
                        />
                        <path
                            d="M9.37012 6.46014H17.5394"
                            stroke="#CCFF00"
                            strokeWidth="0.600158"
                            strokeLinecap="round"
                        />
                        <path
                            d="M17.9876 11.3953H43.3966C45.1435 11.3953 46.5727 12.8154 46.5727 14.551V33.4856C46.5727 35.2213 45.1435 36.6413 43.3966 36.6413H17.9876C16.2408 36.6413 14.8115 35.2213 14.8115 33.4856V14.551C14.8115 12.8154 16.2408 11.3953 17.9876 11.3953Z"
                            fill="#2A3280"
                            stroke="#2A3280"
                            strokeWidth="0.600158"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M14.5889 14.4934L30.7006 25.3858L46.8123 14.4934V15.4011L30.7006 26.2935L14.5889 15.4011V14.4934Z"
                            fill="#CCFF00"
                        />
                    </svg>
                ),
                $_spent_with_furnishings_household: (
                    <svg
                        width={size}
                        height={size}
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M45.5164 9.9978H2.48633V40.8939H45.5164V9.9978Z"
                            fill="#3B46B2"
                            stroke="#3B46B2"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M46.8012 7.20001H1.20117V9.98952H46.8012V7.20001Z"
                            fill="#2A3280"
                            stroke="#2A3280"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M4.41238 38.9588V30.3447H43.5892V38.9588H4.41238Z"
                            fill="#2A3280"
                            stroke="#3B46B2"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M4.41238 29.7528V21.1386H43.5892V29.7528H4.41238Z"
                            fill="#2A3280"
                            stroke="#3B46B2"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M4.41238 20.5385V11.9243H43.5892V20.5385H4.41238Z"
                            fill="#2A3280"
                            stroke="#3B46B2"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M23.9968 17.5854C24.7469 17.5854 25.355 16.9773 25.355 16.2272C25.355 15.4771 24.7469 14.8691 23.9968 14.8691C23.2467 14.8691 22.6387 15.4771 22.6387 16.2272C22.6387 16.9773 23.2467 17.5854 23.9968 17.5854Z"
                            fill="#CCFF00"
                            stroke="#CCFF00"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M23.9968 26.7997C24.7469 26.7997 25.355 26.1916 25.355 25.4415C25.355 24.6914 24.7469 24.0834 23.9968 24.0834C23.2467 24.0834 22.6387 24.6914 22.6387 25.4415C22.6387 26.1916 23.2467 26.7997 23.9968 26.7997Z"
                            fill="#CCFF00"
                            stroke="#CCFF00"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M23.9968 36.0139C24.7469 36.0139 25.355 35.4058 25.355 34.6557C25.355 33.9056 24.7469 33.2976 23.9968 33.2976C23.2467 33.2976 22.6387 33.9056 22.6387 34.6557C22.6387 35.4058 23.2467 36.0139 23.9968 36.0139Z"
                            fill="#CCFF00"
                            stroke="#CCFF00"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ),
                $_spent_with_food_and_non_alcoholic_beverages: (
                    <svg
                        width={size}
                        height={size}
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_996_1063)">
                            <path
                                d="M27.6475 14.0891H45.7218L43.7673 46.8H29.6019L27.6475 14.0891Z"
                                fill="#3B46B2"
                                stroke="#3B46B2"
                                strokeWidth="0.600158"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M40.644 2.33854L37.9873 4.97661C37.8068 5.15584 37.68 5.39459 37.68 5.64845V9.86876H36.084V4.98617C36.084 4.73232 36.1855 4.4893 36.366 4.30943L39.4974 1.20001L40.644 2.33854Z"
                                fill="#CCFF00"
                                stroke="#CCFF00"
                                strokeWidth="0.600158"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M46.2288 12.2641H27.5357C27.0779 12.2641 26.9639 12.3738 26.9639 12.7945V13.582C26.9639 13.9819 27.0779 14.0891 27.5357 14.0891H46.2288C46.686 14.0891 46.8 13.9819 46.8 13.582V12.7945C46.8 12.3738 46.686 12.2641 46.2288 12.2641Z"
                                fill="#2A3280"
                                stroke="#2A3280"
                                strokeWidth="0.600158"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M43.8205 10.0969H29.9441C28.8646 10.0969 27.9902 11.0674 27.9902 12.2641H45.7743C45.7743 11.0674 44.8993 10.0969 43.8205 10.0969Z"
                                fill="#2A3280"
                                stroke="#2A3280"
                                strokeWidth="0.600158"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M23.3605 22.29H10.4175C5.32815 22.29 1.20215 26.4168 1.20215 31.5071V32.4693C1.20215 32.7997 1.47028 33.0678 1.80055 33.0678H31.9768C32.307 33.0678 32.5752 32.7997 32.5752 32.4693V31.5071C32.5758 26.4161 28.4498 22.29 23.3605 22.29Z"
                                fill="#2A3280"
                                stroke="#2A3280"
                                strokeWidth="0.600158"
                                strokeMiterlimit="10"
                            />
                            <path
                                d="M30.986 46.7729H2.79199C1.91369 46.7729 1.20215 46.0612 1.20215 45.1827V42.9862C1.20215 42.6559 1.47028 42.3877 1.80055 42.3877H31.9768C32.307 42.3877 32.5752 42.6559 32.5752 42.9862V45.1827C32.5758 46.0606 31.8636 46.7729 30.986 46.7729Z"
                                fill="#2A3280"
                                stroke="#2A3280"
                                strokeWidth="0.600158"
                                strokeMiterlimit="10"
                            />
                            <path
                                d="M31.3247 42.375H2.45322C1.76261 42.375 1.20215 41.8223 1.20215 41.1398V40.0918C1.20215 39.4099 1.76196 38.8565 2.45322 38.8565H31.3247C32.0154 38.8565 32.5758 39.4092 32.5758 40.0918V41.1398C32.5758 41.8223 32.0154 42.375 31.3247 42.375Z"
                                fill="#3B46B2"
                                stroke="#3B46B2"
                                strokeWidth="0.600158"
                                strokeMiterlimit="10"
                            />
                            <path
                                d="M31.3247 38.8565H2.45322C1.76261 38.8565 1.20215 38.3037 1.20215 37.6212V36.5732C1.20215 35.8913 1.76196 35.3379 2.45322 35.3379H31.3247C32.0154 35.3379 32.5758 35.8907 32.5758 36.5732V37.6212C32.5758 38.3037 32.0154 38.8565 31.3247 38.8565Z"
                                fill="#3B46B2"
                                stroke="#3B46B2"
                                strokeWidth="0.600158"
                                strokeMiterlimit="10"
                            />
                            <path
                                d="M24.8055 41.2022L27.7373 38.8565H21.873L24.8055 41.2022Z"
                                fill="#CCFF00"
                                stroke="#CCFF00"
                                strokeWidth="0.600158"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M32.5758 34.1651C32.5758 33.5787 32.4292 33.1389 31.9833 33.1389H1.79466C1.34875 33.1389 1.20215 33.5787 1.20215 34.1651C1.20215 34.7515 1.34875 35.1913 1.79466 35.1913C3.25463 35.1913 3.38319 36.7802 5.12345 36.7802C7.0841 36.7802 7.0841 35.2577 9.0454 35.2577C11.0061 35.2577 11.0061 36.7802 12.9667 36.7802C14.9274 36.7802 14.9274 35.2577 16.888 35.2577C18.8487 35.2577 18.8487 36.7802 20.81 36.7802C22.7706 36.7802 22.7706 35.2577 24.7319 35.2577C26.6926 35.2577 26.6926 36.7802 28.6539 36.7802C30.3941 36.7802 30.3767 35.1913 31.9894 35.1913C32.4292 35.1913 32.5758 34.7515 32.5758 34.1651Z"
                                fill="#CCFF00"
                                stroke="#CCFF00"
                                strokeWidth="0.600158"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M8.41602 25.8825H9.24067"
                                stroke="#CCFF00"
                                strokeWidth="0.600158"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M13.7881 25.8825H14.6127"
                                stroke="#CCFF00"
                                strokeWidth="0.600158"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M19.1631 25.8825H19.9877"
                                stroke="#CCFF00"
                                strokeWidth="0.600158"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M24.5352 25.8825H25.3598"
                                stroke="#CCFF00"
                                strokeWidth="0.600158"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M5.73047 29.4755H6.55512"
                                stroke="#CCFF00"
                                strokeWidth="0.600158"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M11.1025 29.4755H11.9272"
                                stroke="#CCFF00"
                                strokeWidth="0.600158"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M16.4756 29.4755H17.3002"
                                stroke="#CCFF00"
                                strokeWidth="0.600158"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M21.8496 29.4755H22.6743"
                                stroke="#CCFF00"
                                strokeWidth="0.600158"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M27.2236 29.4755H28.0483"
                                stroke="#CCFF00"
                                strokeWidth="0.600158"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M1.20215 38.8668L32.5841 38.8668"
                                stroke="#CCFF00"
                                strokeWidth="0.600158"
                                strokeLinecap="round"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_996_1063">
                                <rect width="48" height="48" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                ),
                $_spent_with_restaurants_and_hotels: (
                    <svg
                        width={size}
                        height={size}
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M24.0459 15.3243V11.8166"
                            stroke="#CCFF00"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M24.0479 11.8175C25.0911 11.8175 25.9367 10.9719 25.9367 9.92874C25.9367 8.8856 25.0911 8.03998 24.0479 8.03998C23.0048 8.03998 22.1592 8.8856 22.1592 9.92874C22.1592 10.9719 23.0048 11.8175 24.0479 11.8175Z"
                            fill="#CCFF00"
                            stroke="#CCFF00"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M44.8243 36.1013C44.8243 24.5889 35.4704 15.325 24.0479 15.325C12.6254 15.325 3.18164 24.5889 3.18164 36.1013"
                            fill="#3B46B2"
                        />
                        <path
                            d="M44.8243 36.1013C44.8243 24.5889 35.4704 15.325 24.0479 15.325C12.6254 15.325 3.18164 24.5889 3.18164 36.1013H44.8243Z"
                            stroke="#3B46B2"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M44.9124 39.8787H3.08993C2.01064 39.8787 1.20117 39.0692 1.20117 37.99C1.20117 36.9107 2.01064 36.1012 3.08993 36.1012H44.9124C45.9917 36.1012 46.8012 36.9107 46.8012 37.99C46.8012 39.0692 45.9917 39.8787 44.9124 39.8787Z"
                            fill="#2A3280"
                            stroke="#2A3280"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ),
                $_spent_with_clothing_and_footwear: (
                    <svg
                        width={size}
                        height={size}
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M30.4489 12.8962C30.3492 16.1313 27.4847 18.7108 23.9789 18.7108C20.4731 18.7108 17.6085 16.117 17.5088 12.8962C19.2617 13.7656 21.5134 14.2929 23.9789 14.2929C26.4443 14.2929 28.696 13.7656 30.4489 12.8962Z"
                            fill="#2A3280"
                            stroke="#2A3280"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M23.9784 9.80371L5.82227 20.0789H42.163L23.9784 9.80371Z"
                            stroke="#CCFF00"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M23.9784 9.80367V7.66598C23.9784 6.91067 24.3917 6.2266 25.0615 5.89883C25.9309 5.47129 26.5009 4.54495 26.4297 3.49036C26.3442 2.29325 25.3893 1.32417 24.2065 1.21016C23.0806 1.1104 22.0973 1.75171 21.6982 2.70654"
                            stroke="#CCFF00"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M23.9789 18.725C20.4731 18.725 17.6086 16.1313 17.5089 12.9105L3.59961 17.0719L4.93923 25.6084H11.9509V46.8H23.9789H36.007V25.5941H43.0186L44.3582 17.0576L30.449 12.8962C30.3492 16.1313 27.499 18.725 23.9789 18.725Z"
                            fill="#3B46B2"
                            stroke="#3B46B2"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ),
                $_spent_with_transport: (
                    <svg
                        width={size}
                        height={size}
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6.05004 31.1006H1.20117V25.4264C1.20117 23.982 2.28443 22.744 3.72877 22.5377L13.6328 21.1449L21.0093 13.7169C21.5767 13.1495 22.2989 12.84 23.1243 12.84H37.2582C37.8256 12.84 38.393 13.0463 38.8057 13.459L46.1306 20.6291C46.5433 21.0418 46.8012 21.6092 46.8012 22.2282V31.1522H42.5197"
                            fill="#3B46B2"
                        />
                        <path
                            d="M6.05004 31.1006H1.20117V25.4264C1.20117 23.982 2.28443 22.744 3.72877 22.5377L13.6328 21.1449L21.0093 13.7169C21.5767 13.1495 22.2989 12.84 23.1243 12.84H37.2582C37.8256 12.84 38.393 13.0463 38.8057 13.459L46.1306 20.6291C46.5433 21.0418 46.8012 21.6092 46.8012 22.2282V31.1522H42.5197L6.05004 31.1006Z"
                            stroke="#3B46B2"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M21.8966 14.5223L15.3369 21.048H27.1929V13.752H23.7773C23.0434 13.752 22.4012 14.0239 21.8966 14.5223Z"
                            fill="#2A3280"
                            stroke="#2A3280"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M10.485 35.2276C12.9351 35.2276 14.9212 33.2414 14.9212 30.7914C14.9212 28.3413 12.9351 26.3552 10.485 26.3552C8.03498 26.3552 6.04883 28.3413 6.04883 30.7914C6.04883 33.2414 8.03498 35.2276 10.485 35.2276Z"
                            fill="#CCFF00"
                            stroke="#CCFF00"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M38.1344 35.2276C40.5845 35.2276 42.5706 33.2414 42.5706 30.7914C42.5706 28.3413 40.5845 26.3552 38.1344 26.3552C35.6844 26.3552 33.6982 28.3413 33.6982 30.7914C33.6982 33.2414 35.6844 35.2276 38.1344 35.2276Z"
                            fill="#CCFF00"
                            stroke="#CCFF00"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M28.5615 21.048H43.4728C44.0247 21.048 44.2546 20.4135 43.8867 20.051L37.3564 13.752"
                            fill="#2A3280"
                        />
                        <path
                            d="M28.5615 21.048H43.4728C44.0247 21.048 44.2546 20.4135 43.8867 20.051L37.3564 13.752H28.5615V21.048Z"
                            stroke="#2A3280"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M28.5615 13.752H37.2879V21.048H28.5615V13.752Z"
                            fill="#2A3280"
                            stroke="#2A3280"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M26.2699 24.0336H23.4844"
                            stroke="#CCFF00"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ),
                $_spent_with_health: (
                    <svg
                        width={size}
                        height={size}
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_996_1295)">
                            <circle
                                cx="24.0062"
                                cy="24.0064"
                                r="22.806"
                                fill="#3B46B2"
                                stroke="#3B46B2"
                                strokeWidth="0.600158"
                            />
                            <path
                                d="M18.974 10.7028C18.8149 10.7028 18.6859 10.8318 18.6859 10.9909V18.6848L10.9912 18.6848C10.8321 18.6848 10.7031 18.8137 10.7031 18.9728L10.7031 29.0395C10.7031 29.1986 10.8321 29.3276 10.9912 29.3276H18.6859V37.0218C18.6859 37.1809 18.8149 37.3098 18.974 37.3098H29.0406C29.1997 37.3098 29.3287 37.1809 29.3287 37.0218V29.3276H37.0221C37.1812 29.3276 37.3101 29.1986 37.3101 29.0395V18.9728C37.3101 18.8137 37.1812 18.6848 37.0221 18.6848L29.3287 18.6848V10.9909C29.3287 10.8318 29.1997 10.7028 29.0406 10.7028H18.974Z"
                                fill="#CCFF00"
                                stroke="#CCFF00"
                                strokeWidth="0.600158"
                                strokeLinejoin="round"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_996_1295">
                                <rect width="48" height="48" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                ),
                $_spent_with_recreation_and_culture: (
                    <svg
                        width={size}
                        height={size}
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M45.3765 46.5127H4.15656C3.33515 46.5127 2.66309 45.8406 2.66309 45.0192V17.5392H46.7953V45.0192C46.87 45.8406 46.1979 46.5127 45.3765 46.5127Z"
                            fill="#3B46B2"
                            stroke="#3B46B2"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M46.7953 17.5392H2.66309V24.7079H46.7953V17.5392Z"
                            fill="#2A3280"
                            stroke="#2A3280"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M9.90582 17.5392L5.64941 24.7079H12.4865L16.7045 17.5392H9.90582Z"
                            fill="#CCFF00"
                            stroke="#CCFF00"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                        />
                        <path
                            d="M37.0884 17.5392L32.832 24.7079H39.6188L43.8368 17.5392H37.0884Z"
                            fill="#CCFF00"
                            stroke="#CCFF00"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                        />
                        <path
                            d="M23.4976 17.5392L19.2412 24.7079H26.0532L30.2712 17.5392H23.4976Z"
                            fill="#CCFF00"
                            stroke="#CCFF00"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                        />
                        <path
                            d="M44.3943 1.43993L1.20215 10.5091L2.67531 17.5251L45.8675 8.45592L44.3943 1.43993Z"
                            fill="#2A3280"
                            stroke="#2A3280"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M8.26593 9.02693L5.65234 16.9423L12.1461 15.5759L14.9961 7.59595L8.26593 9.02693Z"
                            fill="#CCFF00"
                            stroke="#CCFF00"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                        />
                        <path
                            d="M34.8494 3.50097L32.1611 11.3417L38.8225 9.87602L41.4445 2.12402L34.8494 3.50097Z"
                            fill="#CCFF00"
                            stroke="#CCFF00"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                        />
                        <path
                            d="M21.5574 6.26372L18.8691 14.1045L25.4838 12.7259L28.2198 4.85992L21.5574 6.26372Z"
                            fill="#CCFF00"
                            stroke="#CCFF00"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                        />
                        <path
                            d="M21.7793 29.5625H41.5678"
                            stroke="#2A3280"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M21.7793 35.6107H41.5678"
                            stroke="#2A3280"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M7.96582 41.6596H41.569"
                            stroke="#2A3280"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M15.4332 29.5625H7.96582V37.0299H15.4332V29.5625Z"
                            fill="#2A3280"
                            stroke="#2A3280"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ),
                $_spent_with_education: (
                    <svg
                        width={size}
                        height={size}
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M39.5571 19.9811V29.8948C39.5571 30.9618 38.8271 31.8603 37.7039 32.1411C30.1787 34.0505 19.6211 34.0505 12.0397 32.1411C10.9166 31.8603 10.1865 30.9618 10.1865 29.8948V19.9811"
                            fill="#2A3280"
                        />
                        <path
                            d="M39.5571 19.9811V29.8948C39.5571 30.9618 38.8271 31.8603 37.7039 32.1411C30.1787 34.0505 19.6211 34.0505 12.0397 32.1411C10.9166 31.8603 10.1865 30.9618 10.1865 29.8948V19.9811H39.5571Z"
                            stroke="#2A3280"
                            strokeWidth="0.960253"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M25.5179 25.1214C25.0686 25.2899 24.6755 25.2899 24.2262 25.1214L2.83008 17.4839L24.1701 9.84645C24.6194 9.67797 25.0125 9.67797 25.4617 9.84645L46.8017 17.4839L25.5179 25.1214Z"
                            fill="#3B46B2"
                            stroke="#3B46B2"
                            strokeWidth="0.960253"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M2.83073 32.1411C3.73017 32.1411 4.45931 31.4119 4.45931 30.5125C4.45931 29.613 3.73017 28.8839 2.83073 28.8839C1.93129 28.8839 1.20215 29.613 1.20215 30.5125C1.20215 31.4119 1.93129 32.1411 2.83073 32.1411Z"
                            fill="#CCFF00"
                            stroke="#CCFF00"
                            strokeWidth="0.960253"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M1.48438 38.2621L2.21443 32.1409C2.43906 32.197 2.66369 32.2532 2.88832 32.2532C3.11296 32.2532 3.33759 32.197 3.56222 32.1409L4.29227 38.2621H1.48438Z"
                            fill="#CCFF00"
                            stroke="#CCFF00"
                            strokeWidth="0.960253"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M2.83008 28.8841V17.484H24.2271"
                            stroke="#2A3280"
                            strokeWidth="0.960253"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ),
                $_spent_with_alcoholic_beverages_tobacco: (
                    <svg
                        width={size}
                        height={size}
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M18.3491 46.8C19.5073 46.8 20.446 45.8613 20.446 44.7031V18.7435C20.446 15.8323 20.446 14.5909 17.6333 13.4725C14.8276 12.357 14.8206 11.1128 14.8206 8.20163V3.91138H11.7269V8.20163C11.7269 11.1128 11.7199 12.357 8.91425 13.4725C6.10156 14.5909 6.10156 15.8323 6.10156 18.7435V44.7031C6.10156 45.8613 7.04029 46.8 8.1985 46.8H18.3491Z"
                            fill="#3B46B2"
                            stroke="#3B46B2"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M14.9324 3.91054H11.6179C11.4711 3.91054 11.3516 3.79032 11.3516 3.64424V1.46626C11.3516 1.31947 11.4718 1.19995 11.6179 1.19995H14.9324C15.0792 1.19995 15.1987 1.32017 15.1987 1.46626V3.64424C15.1987 3.79102 15.0785 3.91054 14.9324 3.91054Z"
                            fill="#2A3280"
                            stroke="#2A3280"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M17.6037 22.86H5.75977V37.452H17.6037C17.7363 37.452 17.8438 37.3445 17.8438 37.2119V23.1C17.8438 22.9675 17.7363 22.86 17.6037 22.86Z"
                            fill="#CCFF00"
                        />
                        <path
                            d="M22.6327 40.3019H17.3887V44.2919H22.6327V40.3019Z"
                            fill="#2A3280"
                            stroke="#2A3280"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M42.0127 40.3019H39.8467V44.2919H42.0127V40.3019Z"
                            fill="#CCFF00"
                            stroke="#CCFF00"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M39.7331 40.3019H22.7471V44.2919H39.7331V40.3019Z"
                            fill="#3B46B2"
                            stroke="#3B46B2"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                        />
                        <path
                            d="M39.96 38.592C39.96 37.642 40.8147 37.642 40.8147 36.692C40.8147 35.742 39.96 35.742 39.96 34.792C39.96 33.842 40.8147 33.842 40.8147 32.892"
                            stroke="#CCFF00"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M41.3857 38.592C41.3857 37.642 42.2405 37.642 42.2405 36.692C42.2405 35.742 41.3857 35.742 41.3857 34.792C41.3857 33.842 42.2405 33.842 42.2405 32.892"
                            stroke="#CCFF00"
                            strokeWidth="0.600158"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                ),
                $_spent_with_miscellaneous_goods: (
                    <svg
                        width={size}
                        height={size}
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_996_1590)">
                            <circle
                                cx="24.0072"
                                cy="24.0063"
                                r="22.806"
                                fill="#3B46B2"
                                stroke="#3B46B2"
                                strokeWidth="0.960253"
                            />
                            <circle
                                cx="13.974"
                                cy="24.0063"
                                r="2.73672"
                                fill="#CCFF00"
                            />
                            <circle
                                cx="24.0082"
                                cy="24.0063"
                                r="2.73672"
                                fill="#CCFF00"
                            />
                            <circle
                                cx="34.0434"
                                cy="24.0063"
                                r="2.73672"
                                fill="#CCFF00"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_996_1590">
                                <rect width="48" height="48" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                ),
                totalPopulation: (
                    <svg
                        width={size}
                        height={size}
                        viewBox="0 0 48 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M34.1579 25.3851C37.3458 25.3851 39.93 22.8008 39.93 19.613C39.93 16.4251 37.3458 13.8408 34.1579 13.8408C30.97 13.8408 28.3857 16.4251 28.3857 19.613C28.3857 22.8008 30.97 25.3851 34.1579 25.3851Z"
                            fill="#CCFF00"
                        />
                        <path
                            d="M17.5341 20.7674C21.6783 20.7674 25.0379 17.4078 25.0379 13.2636C25.0379 9.11933 21.6783 5.75977 17.5341 5.75977C13.3898 5.75977 10.0303 9.11933 10.0303 13.2636C10.0303 17.4078 13.3898 20.7674 17.5341 20.7674Z"
                            fill="#3B46B2"
                        />
                        <path
                            d="M47.0878 42.1627C47.0878 34.4676 41.2988 27.0781 34.1584 27.0781C27.0181 27.0781 21.2285 34.467 21.2285 42.1627H47.0878Z"
                            fill="#CCFF00"
                        />
                        <path
                            d="M34.1578 42.2396C34.1578 32.3459 26.7148 22.8452 17.5343 22.8452C8.35386 22.8452 0.910156 32.3452 0.910156 42.2396H34.1578Z"
                            fill="#3B46B2"
                        />
                    </svg>
                ),
                learnMore: (
                    <svg
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_996_18367)">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3.5 5H9.5V7H5.5V19H17.5V15H19.5V21H3.5V5Z"
                                fill={primaryColor}
                            />
                            <path
                                d="M11.5 3V5H18.09L7.5 15.59L8.91 17L19.5 6.41V13H21.5V3H11.5Z"
                                fill={primaryColor}
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_996_18367">
                                <rect
                                    width="24"
                                    height="24"
                                    fill="white"
                                    transform="translate(0.5)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                ),
                exploreArrow: (
                    <svg
                        width="25"
                        height="8"
                        viewBox="0 0 25 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M24.3536 4.35355C24.5488 4.15829 24.5488 3.84171 24.3536 3.64645L21.1716 0.464466C20.9763 0.269204 20.6597 0.269204 20.4645 0.464466C20.2692 0.659728 20.2692 0.976311 20.4645 1.17157L23.2929 4L20.4645 6.82843C20.2692 7.02369 20.2692 7.34027 20.4645 7.53553C20.6597 7.7308 20.9763 7.7308 21.1716 7.53553L24.3536 4.35355ZM0 4.5H24V3.5H0V4.5Z"
                            fill="#CCFF00"
                        />
                    </svg>
                )
            }[iconName]
        }
    </span>
);

export default DynamicSvg;
