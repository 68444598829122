import { autoCompleteStyles } from "utils/autoComplete";
import InputSearch from "components/Inputs/Search";
import { useCategoriesClock } from "context/CategoriesClock";
import { t } from "i18next";
import InputSelect from "components/Inputs/Select";
import Legend from "components/Main/Map/Filters/Legend";
import Actions from "components/Main/Map/Actions";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import useWindowResize from "hooks/useWindowResize";

const Filters = () => {
  const { isMapViewActive, category, countries, measure } =
    useCategoriesClock();
  const { isMobile } = useWindowResize();

  return (
    <article tw="w-full bg-blue-800 flex flex-col justify-between">
      <div tw="min1441:pl-[20%]">
        <div tw="lg:flex hidden p-4 sm:px-4 justify-center">
          <Actions />
        </div>
        <div tw="p-[4rem 1rem 1rem] lg:p-4">
          <h2 tw="text-green-400 text-[2rem] md:text-xl leading-[2rem] mb-4 font-semiBold">
            {t(isMapViewActive ? "map_header" : "categories_header")}
          </h2>
          <p
            css={[
              tw`font-medium text-white md:text-sm`,
              !isMapViewActive && tw`mb-4 md:mb-2`,
            ]}>
            {t(isMapViewActive ? "map_subheader" : "categories_subheader")}
          </p>
          {isMapViewActive && (
            <div tw="my-8 md:my-4 relative border border-blue-800 rounded-[3.125rem] overflow-hidden hover:bg-green-50">
              <InputSearch
                value={countries.mapCountry}
                name="mapCountry"
                autoCompleteStyles={autoCompleteStyles}
                placeholder={t("Search")}
              />
            </div>
          )}
          <div
            tw="lg:grid grid-cols-1 gap-4"
            css={[isMapViewActive && tw`grid-cols-2`]}>
            {!countries.mapCountry && (
              <div>
                {isMapViewActive && (
                  <h3 tw="text-green-400 font-semiBold md:text-sm">
                    {t("filter")}
                  </h3>
                )}
                <InputSelect
                  name="category"
                  value={category}
                  isFromMap={true}
                />
              </div>
            )}
            {(isMapViewActive ||
              (!isMapViewActive && category !== "all_categories")) && (
              <div>
                <h3 tw="text-green-400 font-semiBold md:text-sm">
                  {t("measure")}
                </h3>
                <InputSelect name="measure" value={measure} isFromMap={true} />
              </div>
            )}
          </div>
        </div>
      </div>
      {isMapViewActive && !isMobile && <Legend />}
    </article>
  );
};

export default Filters;
